import { useContext, useEffect, useState } from "react"
import { TextStyle } from "../App"
import { Collapse } from "../Components/Collapse"
import { ContentTile, StyledButton } from "../Components/ModeTile"
import { db } from "../Database/db"
import { RowLayout, InnerTile, ColumnLayout } from "../Styles/Styles"
import { ItemHistoryContainer } from "../Types/ItemHistoryContainer"
import { ProcessedText } from "../Types/ProcessedText"
import { DictionaryModal } from "../Views/DictionaryView"
import { TextHelperModal } from "../Views/TextHelperView"
import { Modal } from "../Components/Modal"
import { ItemHistoryFromProcessedText } from "../Utility/ProcessedTextFromSegmentedOutput"
import { NextItemContainer, OrderSelectedUnits, ZhLanguageConfiguration } from "../Config/LanguageDefinitionConfiguration"
import { RoundControllerParams } from "../Views/RoundView"
import { LearnView } from "../Views/LearnView"
import { ZhBaseUnitTestDefinition } from "../Types/Zh/LanguageDefinition"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { ResponseType } from "../Contexts/Api/RootApiContext"

export const DictionaryQueryTile: React.FC<{ Text: string }> = props => {
    const [showDictionary, setShowDictionary] = useState(false)

    return <>
        <InnerTile onClick={() => { setShowDictionary(true) }} style={{ width: 'auto' }} Small={true}>
            {props.Text}
        </InnerTile>
        {showDictionary && <DictionaryModal OnClose={() => setShowDictionary(false)} Query={props.Text} />}

    </>
}

export const TextBreakdownDisplay: React.FC<{ ihcs: ItemHistoryContainer }> = props => {
    const [showTextHelper, setShowTextHelper] = useState(false)
    const [showStudyView, setShowStudyView] = useState(false)
    const ihcs = props.ihcs

    return <>
        <Collapse Label={ihcs.OriginalText?.target!!} Collapsed={true}>
            <ColumnLayout>
                <RowLayout>
                    <StyledButton onClick={() => { setShowStudyView(true) }}>Study</StyledButton>
                    <StyledButton onClick={() => { setShowTextHelper(true) }}>TextHelper</StyledButton>

                </RowLayout>
                <TextStyle>{ihcs.OriginalText?.primary}</TextStyle>
                <RowLayout>

                    {ihcs.SegmentUnits.map((s, i) => {

                        return <DictionaryQueryTile key={i} Text={s.Segment} />

                    })}
                </RowLayout>
            </ColumnLayout>
            {showTextHelper && <TextHelperModal OnClose={() => setShowTextHelper(false)} Text={ihcs.OriginalText?.target!!} />}
            {showStudyView && <TextStudyView Text={{ Id: -1, ItemHistoryContainers: [ihcs], Focused: false, Label: "", Date: new Date() }} OnClose={() => setShowStudyView(false)} />}
        </Collapse>
    </>
}

export const ProcessedTextDisplay: React.FC<{ Text: ProcessedText }> = props => {

    const [toggleView, setToggleView] = useState(false)


    const [studyView, setStudyView] = useState(false)
    // const [focusButttonDisabled, setFocusButtonDisabled ]= useState(false)
    const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false)

    return <>
        <Collapse Label={props.Text.Label} Collapsed={true}>

            <RowLayout>
                {/* {!props.Text.Focused && <StyledButton disabled={focusButttonDisabled}  onClick={() => db.Texts.update(props.Text.Id, { "Focused": true }).then(()=>setFocusButtonDisabled(true))} >Focus</StyledButton>}
                {props.Text.Focused && <StyledButton disabled={focusButttonDisabled} onClick={() => db.Texts.update(props.Text.Id, { "Focused": false }).then(()=>setFocusButtonDisabled(true))} >Unfocus</StyledButton>} */}
                <StyledButton onClick={() => { setStudyView(!studyView) }}>Study</StyledButton>
                <StyledButton onClick={() => { setToggleView(!toggleView) }}>View</StyledButton>
                <StyledButton disabled={deleteButtonDisabled} onClick={() => { db.Texts.delete(props.Text.Id).then(() => setDeleteButtonDisabled(true)) }}>Delete</StyledButton>

            </RowLayout>

            {studyView && <>
                <TextStudyView Text={props.Text} OnClose={() => setStudyView(false)} />
            </>}

            {toggleView && props.Text.ItemHistoryContainers.map(ihcs => <TextBreakdownDisplay ihcs={ihcs} />)}

        </Collapse>

    </>
}

export const LoadStudyUnitsView: React.FC<{ Text: ProcessedText, Params: RoundControllerParams, OnClose: () => any }> = props => {

    const api = useContext(ApiContext)

    const [nextUnits, setNextUnits] = useState<NextItemContainer | undefined>(undefined);
    const [extraLdu, setExtraLdu] = useState<any | undefined>(undefined)


    const [isSet, setIsSet] = useState(false)
    useEffect(() => {
        if (!isSet) {
            ((pt: ProcessedText) => {
                var count = 0;
                pt.ItemHistoryContainers.map(ihcs => ihcs.SegmentUnits.map(su => { count += su.BaseItems.length }))
                return ItemHistoryFromProcessedText(pt, {
                    Counts: {
                        New: { 1: count, 2: count },
                        Review: props.Params.RoundSize
                    },
                    OrderBy: OrderSelectedUnits.Frequency,
                    TestDefinitions: props.Params.TestDefinitions
                })
            })(props.Text)
                .then(setNextUnits)
                // .then(() => { return api.AppUnits() }).then((resp) => { if (resp.responseType == ResponseType.Success) { setExtraLdu(resp.object.units) } })
                .then(() => setIsSet(true))
        }

    }, [isSet, props.Text, nextUnits]);

    console.log(nextUnits)

    return <>
        {!isSet && <TextStyle>Loading...</TextStyle>}
        {isSet && <LearnView
            OnExit={() => { props.OnClose(); }}
            Data={{
                Params: props.Params,
                TextRefMap: {},
                NextUnits: nextUnits!!,
                LanguageConfiguration: ZhLanguageConfiguration,
                ExtraLanguageDefinition: extraLdu,
                RoundRecords: []
            }}
            State={{
                UseSubUnit: false,
                SubUnitId: 0,
                RetryRound: false,
                RoundRecordId: 0
            }}
            SetMenu={(a: any) => { }}
            Retry={(r) => { }}
        />}
    </>
}

export const TextStudyView: React.FC<{ Text: ProcessedText, OnClose: () => any }> = props => {

    const [startStudy, setStartStudy] = useState(false)
    const [roundParams, setRoundParams] = useState<RoundControllerParams | undefined>(undefined)
    const [activeTestDefinitions, setActiveTestDefinitions] = useState([ZhLanguageConfiguration.PrimaryTestDefinitionId])



    const StartStudy = (howManyNew: number, howManyReviews: number, tds: number[]) => {
        const settings: RoundControllerParams = {
            IntroduceNew: true,
            IntroduceCount: howManyNew,
            RoundSize: howManyReviews,
            PromoteOnNth: 1,
            FailOnNth: 1,
            ShowUntilCorrect: true,
            ShuffleOnIncorrect: false,
            SaveResults: true,
            TimeLimit: 0,
            TestDefinitions: tds
        }
        setRoundParams(settings)
        setStartStudy(true)
    }

    console.log(activeTestDefinitions)

    console.log(roundParams)

    return <Modal OnClose={() => { props.OnClose() }}>
        <ContentTile>
            {!startStudy && <>

                <ColumnLayout>
                    <TextStyle>Settings</TextStyle>
                    <TextStyle>TestDefinitions</TextStyle>
                    <div>
                        {Object.entries(ZhLanguageConfiguration.BaseUnitTestDefinitions).map((d) => {
                            return <RowLayout>
                                <input
                                    type="checkbox"
                                    checked={activeTestDefinitions.includes(d[1].id)}
                                    disabled={ZhLanguageConfiguration.PrimaryTestDefinitionId == d[1].id}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setActiveTestDefinitions([...activeTestDefinitions, d[1].id].sort())
                                        } else {
                                            setActiveTestDefinitions([...activeTestDefinitions.filter(t => t != d[1].id)])
                                        }

                                    }}
                                />
                                <TextStyle>{d[1].Label}</TextStyle>
                            </RowLayout>
                        })}

                    </div>

                    <TextStyle>Lesson Length:</TextStyle>
                    <RowLayout>
                        <StyledButton onClick={() => StartStudy(3, 10, activeTestDefinitions)}>One minute (3 units)</StyledButton>
                        <StyledButton onClick={() => StartStudy(10, 20, activeTestDefinitions)}>Five minutes(10 units)</StyledButton>
                        <StyledButton onClick={() => StartStudy(20, 30, activeTestDefinitions)}>Ten minutes(20 units)</StyledButton>
                    </RowLayout>
                </ColumnLayout>
            </>}
            {startStudy && <>
                {/* <LoadStudyUnitsView Params={roundParams!!} OnClose={props.OnClose} Text={props.Text} /> */}
            </>}
        </ContentTile>
    </Modal>
}