import { useContext, useState } from "react"
import { TextStyle } from "../App"
import { GenerateTextParametersView } from "./GenerateTextParametersView"
import { GenerateTextRequest, ProcessedTextStructure, TextPromptType } from "../api"
import { TextStructure } from "../Database/TextStore"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { ResponseType } from "../Contexts/Api/RootApiContext"
import { TextStructureListenView } from "../Displays/TextStructureDisplay"

export const InfiniteReadView: React.FC = () => {

    const api = useContext(ApiContext)

    enum InfiniteReadViewMode {
        Parameters,
        Generate,
        Read
    }

    const [mode, setMode] = useState(InfiniteReadViewMode.Parameters)

    const [generateTextRequest, setRequest] = useState<GenerateTextRequest | undefined>(undefined)
    const [textStructure, setTextStructure] = useState<TextStructure | undefined>(undefined)
    const [generating, setGenerating] = useState(false)
    const [generated, setGenerated] = useState(false)

    const ApplySettings = (generateTextRequest: GenerateTextRequest) => {
        setRequest(generateTextRequest)
        setMode(InfiniteReadViewMode.Generate)
        Generate(generateTextRequest)
    }



    const Generate = (generateTextRequest: GenerateTextRequest) => {
        setGenerating(true)
        setMode(InfiniteReadViewMode.Generate)
        const currentTime = new Date().toISOString();
        generateTextRequest!!.label = `infinite-${generateTextRequest.promptType == 0 ? generateTextRequest!!.themePrompt : generateTextRequest!!.deriveFrom}-${currentTime}`;
        api.GenerateText(generateTextRequest!!)
            .then((response) => {
                if (response.responseType == ResponseType.Success) {
                    const processedText = response.object
                    const textStructure = {
                        Label: "",
                        Ref: processedText.ref,
                        FullText: processedText.fullText,
                        Chapters: processedText.chapters.map(c => {
                            return {
                                Ref: c.ref,
                                FullText: c.fullText,
                                Label: c.label,
                                Paragraphs: c.paragraphs.map(p => {
                                    return {
                                        Ref: p.ref,
                                        FullText: p.fullText,
                                        Sentences: p.sentences.map(s => {
                                            return {
                                                Ref: s.ref,
                                                Text: s.text,
                                                PrimaryMeaning: s.primaryMeaning,
                                                Segments: s.segments
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                    setTextStructure(textStructure)
                    setMode(InfiniteReadViewMode.Read)
                }
            })
            .finally(() => {
                setGenerating(false)
                setGenerated(true)
                setMode(InfiniteReadViewMode.Read)
            })
    }

    return <>
        {mode == InfiniteReadViewMode.Parameters && <>
            <TextStyle>Infinite Read</TextStyle>
            <GenerateTextParametersView OnComplete={ApplySettings} />
        </>}
        {mode == InfiniteReadViewMode.Generate && <>
            {generating && <TextStyle>Generating...</TextStyle>}
        </>}
        {mode == InfiniteReadViewMode.Read && <>
            <TextStructureListenView Close={() => Generate(generateTextRequest!!)} Structure={textStructure!!.Chapters} />
        </>}
    </>
}