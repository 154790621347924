import { useState } from "react"
import { TextStyle } from "../App"
import { RoundItemData, RoundRecord, RoundView, RoundControllerParams, AnswerRecord } from "./RoundView"
import { ExtraUnitsDefinitionType, SetMenu, State } from "../Loaders/LanguageDefinitionLoader"
import { UnitDefinition, UnitDefinitionMapType } from "../Config/UnitDefinitionMap"
import { AnswerState, ItemHistoryElement } from "../Database/HistoryState"
import { ColumnLayout, InnerTile } from "../Styles/Styles"
import { Data, OnExit } from "../Types/Interfaces"
import { SetNewHistoryFromRoundRecord } from "../Utility/SetHistoryFromRoundItemData"
import { LanguageDefinition } from "../Types/LanguageDefinition"
import { LanguageConfiguration, NextItemContainer } from "../Config/LanguageDefinitionConfiguration"
import { TextRefMap, TextRefType } from "../Database/TextStore"

interface LearnViewState {
    UseSubUnit: boolean
    SubUnitId: number

    RetryRound: boolean
    RoundRecordId: number

}

interface LearnModeData {
    LanguageConfiguration: LanguageConfiguration<any>
    ExtraLanguageDefinition: ExtraUnitsDefinitionType
    TextRefMap: TextRefMap,
    NextUnits: NextItemContainer,
    RoundRecords: RoundRecord[],
    Params: RoundControllerParams,

}


enum LearnModeState {
    Review,
    Results
}

interface Retry {
    Retry: (RoundRecord: RoundRecord) => any
}

const ViewRoundItemData: React.FC<Data<{ UnitDefinition: UnitDefinition<any>, RoundItem: RoundItemData }>> = props => {
    const ri = props.Data.RoundItem

    // var item = props.Data.UnitDefinition.Items.find(i => props.Data.UnitDefinition.GetItemId(i) == ri.UnitItemKey)


    var successPercent = (ri.SuccessCount / ri.Record.length) * 100

    var totalTime = ri.Record.reduce((prev: number, cur: AnswerRecord) => prev + cur.TimeToAnswer, 0)
    var avgTime = totalTime / ri.Record.length

    return <InnerTile>
        {/* <TextStyle size={3}>{props.Data.UnitDefinition.GetItemDisplay(item)}</TextStyle> */}
        <TextStyle>{successPercent.toFixed(0)}% ({ri.SuccessCount}/{ri.FailureCount})</TextStyle>
        <TextStyle>avg time : {avgTime}ms</TextStyle>
    </InnerTile>
}

export const LearnView: React.FC<State<LearnViewState> & OnExit & Retry & SetMenu & Data<LearnModeData>> = props => {

    const [pageState, setPageState] = useState(LearnModeState.Review)

    const [roundItemData, setRoundItemData] = useState<RoundItemData[]>([])
    // const [roundGuid, setRoundGuid] = useState<string | undefined>(undefined)
    const [roundRecord, setRoundRecord] = useState<RoundRecord | undefined>(undefined)

    const [setResultsMenu, setSetResultsMenu] = useState(false)

    const ResetMyState = () => {
        setPageState(LearnModeState.Review)
        setRoundItemData([])
        setSetResultsMenu(false)
    }

    const RetryMenu = [
        {
            Label: "Retry",
            OnClick: () => {
                ResetMyState()
                var mappedRoundData = roundItemData.map(r => {
                    r.Complete = false
                    r.SuccessCount = 0
                    r.FailureCount = 0
                    r.Record = []

                    return r
                })

                roundRecord!!.Items = mappedRoundData

                // props.Retry(roundRecord!!)
            }
        },
        {
            Label: "Continue",
            OnClick: () => props.OnExit()
        }
    ]

    if (!setResultsMenu && pageState == LearnModeState.Results) {
        props.SetMenu(RetryMenu)

        setSetResultsMenu(true)
    }

    // var history = props.Data.History

    // var historyCache = {} as any
    // props.Data.History.map(h => {
    //     historyCache[h.UnitKey] = h
    // })

    // if (props.State.UseSubUnit) {
    //     var subUnit = props.Data.Unit.SubUnits.find(u => u.Id == props.State.SubUnitId)!!
    //     var items = props.Data.Unit.Items.filter(subUnit.GetItems)
    //     history = items.map(i => historyCache[props.Data.Unit.GetItemId(i)])
    // }

    var roundItems = undefined
    var params = props.Data.Params
    params.OrderRound = (Item: any[]) => Item

    var roundGuid = undefined

    // if (props.State.RetryRound) {
    //     var findRoundRecord = props.Data.RoundRecords.find(rr => rr.Id == props.State.RoundRecordId)
    //     if (findRoundRecord) {
    //         roundItems = findRoundRecord.Items
    //         params.IntroduceNew = false
    //         params.OrderRound = (Item: any[]) => Item

    //         var mappedRoundItemData = roundItems.map(
    //             ri => {
    //                 // ri.Complete = false
    //                 // ri.SuccessCount = 0
    //                 // ri.FailureCount = 0
    //                 // ri.Record = []
    //                 return ri
    //             }
    //         )
    //         roundGuid = findRoundRecord.RoundGuid
    //         roundItems = mappedRoundItemData;

    //     }

    // }

    return <>
        {pageState == LearnModeState.Review && <>
            <RoundView
                OnExit={props.OnExit}
                Data={{
                    LanguageConfiguration: props.Data.LanguageConfiguration,
                    ExtraLanguageDefinition: props.Data.ExtraLanguageDefinition,
                    TextRefMap: props.Data.TextRefMap,
                    Params: params,
                    NextUnits: props.Data.NextUnits,
                    // SetData: SetHistoryFromRoundRecord,
                    SetData: SetNewHistoryFromRoundRecord,
                    OnComplete: function (roundRecord: RoundRecord) {

                        setPageState(LearnModeState.Results)
                        setRoundItemData(roundRecord.Items)
                        //setRoundGuid(roundRecord.RoundGuid)
                        setRoundRecord(roundRecord)
                    },
                    OnClose: function () {
                        props.OnExit()
                    },
                    RoundItems: roundItems,
                    RoundGuid: roundGuid

                }}
            />
        </>}
        {pageState == LearnModeState.Results &&

            <>
                <div style={{ textAlign: 'center', paddingBottom: 12, }}>
                    <ColumnLayout>
                        <TextStyle weight='bold' case='uppercase'>Results</TextStyle>
                        <TextStyle>Reviews: {roundItemData.length}</TextStyle>
                        {/* <TextStyle>Correct: {'fix me'}</TextStyle> */}
                        {
                            ((roundItemData: RoundItemData[]) => {
                                var noErrors = 0
                                var oneError = 0
                                var moreErrors = 0

                                var total = roundItemData.length

                                roundItemData.map(ri => {
                                    if (ri.Record.every(r => r.Record == AnswerState.Success)) {
                                        noErrors += 1
                                    }
                                    if (ri.Record.filter(r => r.Record == AnswerState.Failure).length == 1) {
                                        oneError += 1
                                    }
                                    if (ri.Record.filter(r => r.Record == AnswerState.Failure).length > 1) {
                                        moreErrors += 1
                                    }
                                })

                                var outcome = noErrors > oneError + moreErrors ? true : false

                                return <>
                                    <TextStyle weight='bold'>Outcome: {outcome ? "✅" : "❌"}</TextStyle>
                                    <TextStyle>✔ No errors: {noErrors} ({((noErrors / total) * 100).toFixed(2)}%)</TextStyle>
                                    <TextStyle>1️⃣ One error: {oneError} ({((oneError / total) * 100).toFixed(2)}%)</TextStyle>
                                    <TextStyle>❌ Errors: {moreErrors} ({((moreErrors / total) * 100).toFixed(2)}%)</TextStyle>
                                </>
                            })(roundItemData)
                        }
                        {/* <TextStyle>RoundGuid {roundGuid}</TextStyle>
                        <TextStyle>RoundRecord {roundRecord?.Id}</TextStyle> */}
                        {/* <TextStyle>RoundItems: {roundItemData.length}</TextStyle> */}
                        {
                            ((roundItemData: RoundItemData[]) => {
                                var total = 0
                                var entries = 0

                                roundItemData.map(ri => {
                                    total += ri.Record.reduce((acc, item) => { return acc + item.TimeToAnswer }, 0)
                                    entries += ri.Record.length
                                })

                                var avg = total / entries

                                return <>
                                    <TextStyle>Total time: {total / 1000}s</TextStyle>
                                    <TextStyle>Average time: {avg.toFixed(2)}ms</TextStyle>
                                </>
                            })(roundItemData)
                        }
                    </ColumnLayout>
                </div>
                <div style={{ flexDirection: 'row', flexGrow: 1, alignContent: 'center', width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 24 }}>

                    {/* {roundItemData.map(ri => {

                        return <ViewRoundItemData Data={{ UnitDefinition: props.Data.Unit, RoundItem: ri }} />

                    })} */}
                </div>
            </>}


    </>

}
