import { useContext } from "react";
import { Input, Upload } from "antd"
import TextArea from "antd/es/input/TextArea"
import React, { useState, useMemo, useEffect, ChangeEvent } from "react"
import { TextStyle } from "../App"
import { Collapse } from "../Components/Collapse"
import { UnitDefinitionMap } from "../Config/UnitDefinitionMap"
import { HistoryState, ItemHistoryElement } from "../Database/HistoryState"
import { HistoryElementDisplay } from "../Displays/HistoryElementDisplay"
import { ColumnLayout, ControllerModeContainer, InnerTile, RowLayout } from "../Styles/Styles"
import Papa from 'papaparse';
import { PartialChapter, PartialParagraph, PartialSentence, PartialTextStructure, ProcessedTextStructure, ProcessTextRequest, SegmentationOutput, SegmentSentenceRequest, SentenceSegmentationResult, TextPair } from "../api"
import { Paginator } from "../Components/Paginator"
import { db } from "../Database/db"
import { ItemHistoryContainer, SegmentUnit } from "../Types/ItemHistoryContainer"
import { ProcessedText } from "../Types/ProcessedText"
import { SetMenu } from "../Loaders/LanguageDefinitionLoader"
import { ZhLanguageUnitTypes } from "../Types/Zh/LanguageDefinition"
import { OnExit } from "../Types/Interfaces"
import { StyledButton } from "../Components/ModeTile"
import { ProcessedTextFromSegmentationOutput, StoreProcessedTextStructure } from "../Utility/ProcessedTextFromSegmentedOutput"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { ResponseType } from "../Contexts/Api/RootApiContext";
import { UnwrapApiCall } from "../Contexts/Api/DefaultApiContext";
import { DictionaryQueryTile } from "../Displays/ProcessedTextDisplay";
import { ParseTextIntoPartialChapter } from "../Utility/ParseTextIntoProcessTextRequest";

export const ProcessTextView: React.FC<SetMenu & { OnExit: (...a: any) => any }> = props => {

    enum Mode {
        Input,
        View,
        CreateSubUnit,
        LoadAsUnitHistoryList,
        Processed
    }

    const api = useContext(ApiContext)

    const [displayMode, setDisplayMode] = useState(Mode.Input)

    const [text, setText] = useState<string | undefined>(undefined)

    const [process, setProcess] = useState(false)
    const [processed, setProcessed] = useState(false)

    const [uniqueCount, setUniqueCount] = useState<number | undefined>(undefined)
    const [processedList, setProcessedList] = useState<any[]>([])
    const [processedHistory, setProcessedHistory] = useState<ItemHistoryElement[]>([])

    const UploadMenu = [
        {
            Label: 'Process',
            OnClick: () => Process()
        },
        {
            Label: 'Exit',
            OnClick: () => props.OnExit()
        }
    ]

    const ViewMenu = [
        {
            Label: 'Save',
            OnClick: () => LoadAsUnitHistory()
        },
        {
            Label: 'Process again',
            OnClick: () => Reset()
        },
        {
            Label: 'Exit',
            OnClick: () => props.OnExit()
        }
    ]

    const FinalMenu = [
        {
            Label: 'Process again',
            OnClick: () => Reset()
        },
        {
            Label: 'Exit',
            OnClick: () => props.OnExit()
        }
    ]

    useMemo(() => props.SetMenu(UploadMenu), [])

    useEffect(() => {

        if (process) {
            ProcessText(text!!).then(() => setProcessed(true)).then(() => setProcess(false))
        }

    }, [process])



    const Process = () => {

        setProcess(true)
        setDisplayMode(Mode.View)
        props.SetMenu(ViewMenu)
    }

    const Reset = () => {
        setDisplayMode(Mode.Input)
        setText(undefined)
        props.SetMenu(UploadMenu)

    }

    const LoadAsUnitHistory = () => {
        setDisplayMode(Mode.LoadAsUnitHistoryList)
        props.SetMenu(FinalMenu)

    }

    enum UploadType {
        PlainText,
        Csv,
        Image
    }

    const [uploadType, setUploadType] = useState<UploadType>(UploadType.PlainText)
    const [label, setLabel] = useState("")
    const [processedCsvSuccessfully, setProcessedCsvSuccessfully] = useState(false)
    const [csvData, setCsvData] = useState<any>({})
    const [targetField, setTargetField] = useState<string>("")
    const [primaryField, setPrimaryField] = useState("")

    const [uploadImage, setUploadImage] = useState<File | undefined>(undefined)


    const [processedTextStructure, setProcessedText] = useState<ProcessedTextStructure | undefined>(undefined)
    // const [originalText, setOriginalText] = useState<TextPair[]>([])
    // const [segmentationOutput, setSegmentationOutput] = useState<SegmentationOutput[]>([])

    const onUploadCsv = (event: ChangeEvent<HTMLInputElement>) => {


        const file = event.target!!.files!![0];
        if (file) {
            Papa.parse(file as File, {
                header: true,
                skipEmptyLines: true,
                error: (error: Error, file: File) => {
                    setProcessedCsvSuccessfully(false)
                    console.log(error)
                },
                complete: (results: any) => {
                    setProcessedCsvSuccessfully(true)
                    setCsvData(results)
                    console.log(results)
                    setTargetField(results.meta.fields[0])
                    setPrimaryField(results.meta.fields[0])

                }
            })
        }
    }

    const onUploadImage = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target!!.files!![0];
        if (file) {
            setUploadImage(file)
        }
    }

    const ProcessText = async (text: string) => {


        // const segmentRequest = new SegmentSentenceRequest()
        const processTextRequest = new ProcessTextRequest()
        processTextRequest.text = new PartialTextStructure()
        processTextRequest.label = label
        if (uploadType == UploadType.Image) {

            // var apiCall = await api.EvaluateImage(uploadImage!!)

            // var segmentedSentencesResponse = await UnwrapApiCall(apiCall)!!

            // setSegmentationOutput(segmentedSentencesResponse.data)
            // setProcessedList(segmentedSentencesResponse.data)
            // setProcessedHistory([])


        } else {

            if (uploadType == UploadType.PlainText) {

                processTextRequest.text.chapters = [ParseTextIntoPartialChapter(text)]

                // split paragraphs
                const splitText = text.split("\n").filter(t => t.trim() !== "")
                // segmentRequest.textPairs = splitText.map(t => {

                //     const sentences = t.split("。").filter(t => t.trim() !== "")
                //     return sentences.map(s => {
                //         var pair = new TextPair()
                //         pair.target = s
                //         return pair
                //     })
                // }).flat()
            } else if (uploadType == UploadType.Csv) {
                processTextRequest.text.chapters = []
                const chapter: PartialChapter = new PartialChapter()
                const paragraph: PartialParagraph = new PartialParagraph()
                paragraph.sentences = []

                processTextRequest.text.chapters.push(chapter)

                chapter.paragraphs = [paragraph]

                csvData.data.map((d: any) => {
                    const sentence = new PartialSentence()
                    sentence.primaryMeaning = d[primaryField]
                    sentence.text = d[targetField]
                    paragraph.sentences.push(sentence)
                })

                // segmentRequest.textPairs = csvData.data.map((d: any) => {
                //     const pair = new TextPair()
                //     pair.target = d[targetField]
                //     pair.primary = d[primaryField]
                //     return pair
                // })
            }

            // setOriginalText(segmentRequest.textPairs)
            // segmentRequest.translateMissing = true
            // segmentRequest.translateMissingUnit = true

            var response = await api.ProcessText(processTextRequest)
            if (response.responseType == ResponseType.Success) {
                setProcessedText(response.object)
            }

            // var response = await api.SegmentSentences(segmentRequest)
            // if (response.responseType == ResponseType.Success) {
            //     setSegmentationOutput(response.object.data)
            //     setProcessedList(response.object.data)
            //     setProcessedHistory([])
            // } else {

            // }

        }


    }



    return <>
        {displayMode == Mode.Input && <>
            <TextStyle size={2}>Process Text</TextStyle>
            <ColumnLayout>
                <TextStyle size={1}>Options</TextStyle>
                <RowLayout>
                    <TextStyle size={0.75}>Label</TextStyle>
                    <input type="text" onChange={(e) => setLabel(e.target.value)} />
                </RowLayout>
                <RowLayout>
                    <TextStyle size={0.75}>Upload Type</TextStyle>
                    <select defaultValue={uploadType} onChange={(e) => { setUploadType(parseInt(e.target.value)) }}>
                        <option value={UploadType.PlainText}>Textbox</option>
                        <option value={UploadType.Csv}>CSV</option>
                        <option value={UploadType.Image}>Image</option>
                    </select>
                </RowLayout>
                {
                    uploadType == UploadType.PlainText && <>
                        <TextStyle size={1}>Enter text</TextStyle>

                        <TextArea rows={4} onChange={(e) => setText(e.target.value)} />
                    </>
                }
                {
                    uploadType == UploadType.Csv && <>
                        <ColumnLayout>
                            <TextStyle size={1}>Upload csv</TextStyle>
                            <input onChange={onUploadCsv} accept=".csv" type="file" id="csvInput" />
                            {processedCsvSuccessfully && csvData && <>
                                <TextStyle size={1}>Processed csv successfully</TextStyle>
                                <TextStyle size={1}>Select fields to import:</TextStyle>
                                <RowLayout>
                                    <TextStyle size={0.75}>Target</TextStyle>
                                    <select onChange={(e) => setTargetField(e.target.value)}>
                                        {csvData.meta.fields.map((f: string) => {
                                            return <option value={f}>{f}</option>
                                        })}
                                    </select>
                                    <TextStyle size={0.5}>(e.g.) {csvData.data[0][targetField]}</TextStyle>
                                </RowLayout>
                                <RowLayout>
                                    <TextStyle size={0.75}>Primary</TextStyle>
                                    <select onChange={(e) => setPrimaryField(e.target.value)}>
                                        {csvData.meta.fields.map((f: string) => {
                                            return <option value={f}>{f}</option>
                                        })}
                                    </select>
                                    <TextStyle size={0.5}>(e.g.) {csvData.data[0][primaryField]}</TextStyle>

                                </RowLayout>
                            </>}

                        </ColumnLayout>
                    </>
                }
                {uploadType == UploadType.Image && <>
                    <TextStyle size={1}>Upload image</TextStyle>
                    <input onChange={onUploadImage} type="file" accept=".jpg,.png" id="imageInput" />

                </>}

            </ColumnLayout>

        </>}
        {displayMode == Mode.View && <>
            {processed == false && <>
                <TextStyle>Processing...</TextStyle>
            </>}

            {processed && <>
                <TextStyle size={3}>Processed.</TextStyle>

                <Collapse Label="Chapters" Collapsed={false}>
                    {processedTextStructure?.chapters.map(c => {
                        return <>
                            {c.paragraphs.map(p => {
                                return <Collapse Label={"Paragraph: " + p.fullText} Collapsed={true}>
                                    {p.sentences.map(s => {
                                        return <>
                                            <Collapse Label={s.text} Collapsed={true}>

                                                <RowLayout>
                                                    {s.segments.map((p, i) => {
                                                        return <DictionaryQueryTile key={i} Text={p} />
                                                    })}
                                                </RowLayout>

                                            </Collapse>
                                        </>
                                    })}
                                </Collapse>
                            })}
                        </>
                    })}
                </Collapse>


                {/* <Collapse Label="Sentences" Collapsed={true}>
                    {segmentationOutput.map(o => {
                        if (o.output.length > 0) {
                            return <Collapse Label={o.input.target} Collapsed={true}>
                                <RowLayout>
                                    {o.output.map((p, i) => {
                                        return <DictionaryQueryTile key={i} Text={p.unit} />
                                    })}
                                </RowLayout>
                            </Collapse>
                        } else {
                            return <></>
                        }
                    })}

                </Collapse>
                <Collapse Label='Original text' Collapsed={true}>
                    <TextArea defaultValue={text} disabled={true}></TextArea>
                </Collapse> */}


            </>}
        </>}
        {displayMode == Mode.LoadAsUnitHistoryList && <ProcessTextIntoUnitHistory ProcessedTextStructure={processedTextStructure!!} />}

    </>
}

export const ProcessTextIntoUnitHistory: React.FC<{ ProcessedTextStructure: ProcessedTextStructure }> = props => {

    const [title, setTitle] = useState("")
    const [titleSet, setTitleSet] = useState(false)
    const [processed, setProcessed] = useState(false)

    const Process = async () => {
        // const processedText = await ProcessedTextFromSegmentationOutput(title, props.SegmentationOutput)
        // return processedText

        await StoreProcessedTextStructure(title, props.ProcessedTextStructure)

    }

    return <>
        {!titleSet && <>
            <ColumnLayout>
                <TextStyle>Text Title</TextStyle>
                <input type="text" onChange={(e) => setTitle(e.target.value)} />
                <StyledButton onClick={() => {
                    setTitleSet(true);
                    Process().then(() => {
                        setProcessed(true)
                    })
                }}>Save</StyledButton>
            </ColumnLayout>
        </>
        }
        {titleSet && !processed && <><TextStyle>Saving...</TextStyle></>}
        {processed && <TextStyle>Saved.</TextStyle>}
    </>
}