import { useContext, useEffect, useState } from "react"
import { Chapter, Paragraph, Sentence, TextRefMap, TextRefType, TextStructure } from "../Database/TextStore"
import { ContentTile, StyledButton } from "../Components/ModeTile"
import { db } from "../Database/db"
import { ColumnLayout, RowLayout } from "../Styles/Styles"
import { TextStudyView, TextBreakdownDisplay, DictionaryQueryTile } from "./ProcessedTextDisplay"
import { Collapse } from "../Components/Collapse"
import { TextStyle } from "../App"
import { TextHelperModal } from "../Views/TextHelperView"
import { NextItemContainer, OrderSelectedUnits, UpdateUnitState, ZhLanguageConfiguration } from "../Config/LanguageDefinitionConfiguration"
import { RoundControllerParams } from "../Views/RoundView"
import { Modal } from "../Components/Modal"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { ResponseType } from "../Contexts/Api/RootApiContext"
import { ProcessedText } from "../Types/ProcessedText"
import { ItemHistoryFromProcessedText, ItemHistoryFromTextRefStructure, ResolveSegments } from "../Utility/ProcessedTextFromSegmentedOutput"
import { LearnView } from "../Views/LearnView"
import { PlayTextAudio } from "../Components/PlayTextAudio"
import { useQuery } from "@tanstack/react-query"
import { UnwrapApiCall } from "../Contexts/Api/DefaultApiContext"
import { ZhLanguageUnitTypes } from "../Config/Language/Zh/Types"
import { SelectItemParametersView } from "../Views/SelectItemParametersView"
import { ZhLanguageDefinition } from "../Config/Language/Zh/Definition"
import { SelectUnitParameters } from "../api"
import { LearnViewLoader } from "../Loaders/LearnViewLoader"
import { DefaultUnitControllerParams } from "../Database/SettingsState"

export const SentenceDisplay: React.FC<{ Sentence: Sentence }> = props => {
    const [showTextHelper, setShowTextHelper] = useState(false)
    const [showStudyView, setShowStudyView] = useState(false)



    return <>
        <Collapse Label={props.Sentence.Text} Collapsed={true}>
            <ColumnLayout>
                <RowLayout>
                    <StyledButton onClick={() => { setShowStudyView(true) }}>Study</StyledButton>
                    <StyledButton onClick={() => { setShowTextHelper(true) }}>TextHelper</StyledButton>
                    <PlayTextAudio Text={props.Sentence.Text} />

                </RowLayout>
                <TextStyle>{props.Sentence.PrimaryMeaning}</TextStyle>
                <RowLayout>

                    {props.Sentence.Segments.map((s, i) => {

                        return <DictionaryQueryTile key={i} Text={s} />

                    })}
                </RowLayout>
            </ColumnLayout>
            {showTextHelper && <TextHelperModal OnClose={() => setShowTextHelper(false)} Text={props.Sentence.Text} />}
            {showStudyView && <TextStructureStudyView TextRef={props.Sentence.Ref} OnClose={() => setShowStudyView(false)} />}
        </Collapse>
    </>
}


export const SentenceReader: React.FC<{ Paragraph: Paragraph, OnExit: any }> = props => {

    const sentences = props.Paragraph.Sentences

    const [position, setPosition] = useState(0)

    const Next = () => {
        if (position != sentences.length) {
            setPosition(position + 1)
        }
    }

    const Prev = () => {
        if (position > 0) {
            setPosition(position - 1)
        }
    }

    return <>
        <ColumnLayout>
            <TextStyle size={2.5}>{sentences[position].Text}</TextStyle>
            <PlayTextAudio Text={sentences[position].Text} />
            <RowLayout>

                {sentences[position].Segments.map((s, i) => {

                    return <DictionaryQueryTile key={i} Text={s} />

                })}
            </RowLayout>
        </ColumnLayout>
        <RowLayout>

            {position > 0 &&
                <StyledButton onClick={Prev}>Previous</StyledButton>
            }
            {position < sentences.length - 1 &&
                <StyledButton onClick={Next}>Next</StyledButton>
            }
            <StyledButton onClick={props.OnExit}>Finish</StyledButton>
        </RowLayout>
    </>
}

export const AssistedReadView: React.FC<{ Close: any, Structure: Chapter[] }> = props => {

    type ReadViewMode = 'Paragraph' | 'Sentence'
    const paragraphs = props.Structure.map(c => c.Paragraphs).flat()

    const [position, setPosition] = useState(0)

    const [mode, setMode] = useState<ReadViewMode>('Paragraph')

    const Prev = () => {
        if (position > 0) {
            setPosition(position - 1)
        }
    }
    const NextParagraph = () => {
        if (position != paragraphs.length) {
            setPosition(position + 1)
        }
    }

    const SentenceMode = () => {
        setMode('Sentence')
    }

    return <>
        <ContentTile>
            <ColumnLayout>
                {mode == 'Paragraph' && <>
                    < TextStyle size={2}>{paragraphs[position].Sentences.map(s => s.Text).join("")}</TextStyle>
                    {position > 0 &&
                        <StyledButton onClick={Prev}>Previous</StyledButton>
                    }
                    {position < paragraphs.length - 1 &&
                        <StyledButton onClick={NextParagraph}>Next</StyledButton>
                    }
                    <StyledButton onClick={SentenceMode}>By sentence</StyledButton>
                    {position == paragraphs.length - 1 && <StyledButton onClick={props.Close}>Finish</StyledButton>}
                </>}
                {mode == 'Sentence' && <SentenceReader Paragraph={paragraphs[position]} OnExit={() => setMode('Paragraph')} />}
            </ColumnLayout >
        </ContentTile >
    </>
}

export const TextStructureListenView: React.FC<{ Close: any, Structure: Chapter[] }> = props => {

    const paragraphs = props.Structure.map(c => c.Paragraphs).flat()

    const [activeParagraph, setActiveParagraph] = useState(0)
    const [activeSentence, setActiveSentence] = useState(0)

    const checkFinalPosition = () => {
        if (activeParagraph == paragraphs.length - 1) {
            if (activeSentence == paragraphs[activeParagraph].Sentences.length - 1) {
                return true
            }
        }
        return false
    }

    const checkFirstPosition = () => {
        if (activeParagraph == 0 && activeSentence == 0) {
            return true
        }
        return false
    }

    const NextSentence = () => {
        if (activeSentence < paragraphs[activeParagraph].Sentences.length - 1) {
            setActiveSentence(activeSentence + 1)
        } else {
            setActiveParagraph(activeParagraph + 1)
            setActiveSentence(0)
        }
    }

    const PrevSentence = () => {
        if (activeParagraph == 0 && activeSentence == 0) {

        } else {
            if (activeSentence > 0) {
                setActiveSentence(activeSentence - 1)
            } else {
                setActiveParagraph(activeParagraph - 1)
                setActiveSentence(paragraphs[activeParagraph].Sentences.length - 2)
            }
        }
    }

    console.log(activeParagraph, activeSentence)

    return <>
        <ContentTile>
            <ColumnLayout>
                {paragraphs[activeParagraph].Sentences.map((s, i) => {
                    return <>
                        <ColumnLayout>
                            <RowLayout key={`${activeParagraph}-${i}`}>
                                <TextStyle size={2} weight={i == activeSentence ? 'bold' : 'normal'}>{s.Text}</TextStyle>
                                {i == activeSentence && <PlayTextAudio AutoPlay={true} Text={s.Text} />}
                                {i != activeSentence && <PlayTextAudio AutoPlay={false} Text={s.Text} />}


                            </RowLayout >
                            <RowLayout>
                                {i == activeSentence &&
                                    s.Segments.map((s, i) => {

                                        return <DictionaryQueryTile key={i} Text={s} />

                                    })}
                            </RowLayout>

                        </ColumnLayout>
                    </>
                })}
            </ColumnLayout >
            <RowLayout>
                {!checkFirstPosition() && <StyledButton onClick={PrevSentence}>Previous</StyledButton>}
                {!checkFinalPosition() && <StyledButton onClick={NextSentence}>Next</StyledButton>}
                <StyledButton onClick={props.Close}>Finish</StyledButton>
            </RowLayout>
        </ContentTile >
    </>
}

export const TextStructureDisplay: React.FC<{ Label: string, Ref: string }> = props => {

    const [toggleView, setToggleView] = useState(false)
    const [studyView, setStudyView] = useState(false)
    const [displayView, setDisplayView] = useState(false)
    const [readView, setReadView] = useState(false)
    const [listenView, setListenView] = useState(false)

    // const [focusButttonDisabled, setFocusButtonDisabled ]= useState(false)
    const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false)


    const api = useContext(ApiContext)

    const { isPending, error, data } = useQuery({
        queryKey: ['text', props.Ref],
        queryFn: () => api.GetText(props.Ref).then(UnwrapApiCall),
        gcTime: 0,
        staleTime: Infinity
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    var fullText = data.chapters.map(c => c.paragraphs.map(p => p.sentences.map(s => s.text).join("\n")).join("\n\n")).join("")

    const textStructure = {
        Label: props.Label,
        Ref: props.Ref,
        FullText: data.fullText,
        Chapters: data.chapters.map(c => {
            return {
                Ref: c.ref,
                FullText: c.fullText,
                Label: c.label,
                Paragraphs: c.paragraphs.map(p => {
                    return {
                        Ref: p.ref,
                        FullText: p.fullText,
                        Sentences: p.sentences.map(s => {
                            return {
                                Ref: s.ref,
                                Text: s.text,
                                PrimaryMeaning: s.primaryMeaning,
                                Segments: s.segments
                            }
                        })
                    }
                })
            }
        })
    }
    return <>
        <Collapse Label={props.Label} Collapsed={true}>

            <RowLayout>
                {/* {!props.Text.Focused && <StyledButton disabled={focusButttonDisabled}  onClick={() => db.Texts.update(props.Text.Id, { "Focused": true }).then(()=>setFocusButtonDisabled(true))} >Focus</StyledButton>}
                {props.Text.Focused && <StyledButton disabled={focusButttonDisabled} onClick={() => db.Texts.update(props.Text.Id, { "Focused": false }).then(()=>setFocusButtonDisabled(true))} >Unfocus</StyledButton>} */}
                <StyledButton onClick={() => { setDisplayView(!displayView) }}>Display</StyledButton>
                <StyledButton onClick={() => { setReadView(!readView) }}>Assisted Read</StyledButton>
                <StyledButton onClick={() => { setListenView(!listenView) }}>Listen</StyledButton>
                <StyledButton onClick={() => { setStudyView(!studyView) }}>Study</StyledButton>
                <StyledButton onClick={() => { setToggleView(!toggleView) }}>View</StyledButton>
                <StyledButton disabled={deleteButtonDisabled} onClick={() => { api.DeleteText(props.Ref).then(() => setDeleteButtonDisabled(true)) }}>Delete</StyledButton>

            </RowLayout>

            {studyView && <>
                <TextStructureStudyView TextRef={props.Ref} OnClose={() => setStudyView(false)} />
            </>}

            {readView && <>
                <Modal OnClose={() => setReadView(false)} >
                    <AssistedReadView Close={() => setReadView(false)} Structure={textStructure.Chapters} />
                </Modal>
            </>

            }

            {listenView && <>
                <Modal OnClose={() => setListenView(false)}>


                    <TextStructureListenView Close={() => setListenView(false)} Structure={textStructure.Chapters} />
                </Modal>
            </>}
            {displayView && <>
                <Modal OnClose={() => { setDisplayView(false) }}>
                    <ContentTile>
                        <ColumnLayout>
                            {fullText?.split("\n").map(c => { return <TextStyle size={1.25}>{c}</TextStyle> })}
                        </ColumnLayout>
                    </ContentTile>

                </Modal>
            </>}

            {toggleView && <>
                {textStructure.Chapters.map(c => {
                    return <>
                        {c.Paragraphs.map(p => {
                            return <Collapse Label={"Paragraph: " + p.FullText} Collapsed={true}>
                                {p.Sentences.map(s => {
                                    return <>
                                        <SentenceDisplay Sentence={s} />
                                    </>
                                })}
                            </Collapse>
                        })}
                    </>
                })}
            </>}

        </Collapse>

    </>
}

export const TextStructureStudyView: React.FC<{ OnClose: () => any, TextRef: string }> = props => {

    const [startStudy, setStartStudy] = useState(false)
    const [roundParams, setRoundParams] = useState<RoundControllerParams>(DefaultUnitControllerParams())
    const [parameters, setParameters] = useState<SelectUnitParameters>(new SelectUnitParameters())

    const StartStudy = () => {

        var x = JSON.parse(localStorage.getItem("selectItemParameters_fromsingletext")!!)
        console.log(x)
        parameters.parameters = x;
        parameters.textRef = props.TextRef;
        console.log(parameters)
        setParameters(parameters)

        const settings: RoundControllerParams = {
            IntroduceNew: true,
            IntroduceCount: 10,
            RoundSize: 20,
            PromoteOnNth: 1,
            FailOnNth: 1,
            ShowUntilCorrect: true,
            ShuffleOnIncorrect: false,
            SaveResults: true,
            TimeLimit: 0,
            TestDefinitions: [],
            OrderRound: (item) => item
        }
        setRoundParams(settings)
        setStartStudy(true)
    }

    return <Modal OnClose={() => { props.OnClose() }}>
        <ContentTile>
            {!startStudy && <>

                <ColumnLayout>
                    <TextStyle>Settings</TextStyle>

                    <SelectItemParametersView SetData={(x) => { localStorage.setItem("selectItemParameters_fromsingletext", JSON.stringify(x)) }} Data={{ UnitTypes: [ZhLanguageUnitTypes.FromSingleText], LanguageDefinition: ZhLanguageDefinition }} />

                    <StyledButton onClick={StartStudy}>Start</StyledButton>

                </ColumnLayout>
            </>}
            {startStudy && <>
                <LearnViewLoader
                    Data={{ ...roundParams, parameters: parameters }}
                    SetMenu={() => { }}
                    OnExit={() => { props.OnClose() }}
                />
            </>}
        </ContentTile>
    </Modal>
}

// export const LoadStudyUnitsView: React.FC<{ Params: RoundControllerParams, OnClose: () => any }> = props => {

//     const api = useContext(ApiContext)

//     const [nextUnits, setNextUnits] = useState<NextItemContainer | undefined>(undefined);
//     const [extraLdu, setExtraLdu] = useState<any | undefined>(undefined)
//     const [textRefMap, setTextRefMap] = useState<TextRefMap>({})

//     const [isSet, setIsSet] = useState(false)
//     useEffect(() => {
//         // if (!isSet) {

//         //     (() => { return api.AppUnits() })()
//         //         .then((resp) => {
//         //             if (resp.responseType == ResponseType.Success) {
//         //                 setExtraLdu(resp.object.units);
//         //                 UpdateUnitState(ZhLanguageConfiguration, resp.object.units)
//         //             }
//         //         })
//         //         .then(async () => {
//         //             const textRefs = await db.TextRefStore.toArray()
//         //             const TextRefMap: TextRefMap = {}
//         //             textRefs.map(tr => TextRefMap[tr.Ref] = tr)
//         //             setTextRefMap(TextRefMap)
//         //         })
//         //         .then(() => {
//         //             return ((text: TextRefType) => {
//         //                 var count = 0;
//         //                 ResolveSegments(text).map(s => {
//         //                     count += 1 // add the segment
//         //                     count += s.split("").length // add the number of base units
//         //                 })

//         //                 return ItemHistoryFromTextRefStructure(text, {
//         //                     Counts: {
//         //                         New: { 1: props.Params.IntroduceCount, 2: props.Params.IntroduceCount },
//         //                         Review: props.Params.RoundSize
//         //                     },
//         //                     OrderBy: OrderSelectedUnits.Frequency,
//         //                     TestDefinitions: props.Params.TestDefinitions
//         //                 })

//         //             })(props.Text)
//         //         })

//         //         .then(setNextUnits)
//         //         .then(() => setIsSet(true))
//         // }

//     }, [isSet, props.Text, nextUnits]);

//     return <>
//         {!isSet && <TextStyle>Loading...</TextStyle>}
//         {isSet && <LearnView
//             OnExit={() => { props.OnClose(); }}
//             Data={{
//                 Params: props.Params,
//                 NextUnits: nextUnits!!,
//                 LanguageConfiguration: ZhLanguageConfiguration,
//                 ExtraLanguageDefinition: extraLdu,
//                 TextRefMap: textRefMap,
//                 RoundRecords: []
//             }}
//             State={{
//                 UseSubUnit: false,
//                 SubUnitId: 0,
//                 RetryRound: false,
//                 RoundRecordId: 0
//             }}
//             SetMenu={(a: any) => { }}
//             Retry={(r) => { }}
//         />}
//     </>
// }
