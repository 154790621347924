import { useContext } from "react"
import { DashboardResponse } from "../api"
import { TextStyle } from "../App"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { ColumnLayout, RowLayout } from "../Styles/Styles"
import { useQuery } from "@tanstack/react-query"
import { UnwrapApiCall } from "../Contexts/Api/DefaultApiContext"

export const StudyDashboardTile: React.FC = (props) => {

    const api = useContext(ApiContext)

    const { isPending, error, data } = useQuery({
        queryKey: ['dashboard'],
        queryFn: () => api.Dashboard().then(UnwrapApiCall),
        gcTime: 0,
        staleTime: Infinity

    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    return <>
        <ColumnLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
            <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                {data.studiedTodayCheck && <TextStyle>🟢</TextStyle>}
                {!data.studiedTodayCheck && data.studiedYesterdayCheck && <TextStyle>🟡</TextStyle>}
                {!data.studiedTodayCheck && !data.studiedYesterdayCheck && <TextStyle>🔴</TextStyle>}
            </RowLayout>
            {data.studiedYesterdayCheck &&
                <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <TextStyle weight='bold'>Current Streak</TextStyle>
                    <TextStyle>{data.currentStreak}</TextStyle>
                </RowLayout>
            }
            <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                <TextStyle weight='bold'>Longest Streak</TextStyle>
                <TextStyle>{data.longestStreak}</TextStyle>
            </RowLayout>
        </ColumnLayout>
    </>



}