import React, { useState } from 'react';
import { ItemData } from "../api"
import { Data } from "../Types/Interfaces"
import { ContentTile, StyledButton } from '../Components/ModeTile';
import { ColumnLayout, RowLayout } from '../Styles/Styles';
import { NewTestDefinition } from '../Config/UnitDefinitionMap';
import { DisplayItemContentSpace } from '../Components/NewDisplayItem';
import { TextStyle } from '../App';

export const PracticeItemView: React.FC<Data<{
    Item: ItemData,
    TestDefinitions: Record<number, NewTestDefinition>
}>> = props => {

    console.log(props)

    const [activeTest, setActiveTest] = useState<number | undefined>(undefined)
    const [revealFold, setRevealFold] = useState(false)

    const [receivedAnswer, setReceivedAnswer] = useState(false)

    const startPracticeTest = (id: number) => {
        setActiveTest(id)
    }

    const ReceiveAnswer = (answer: boolean) => {
        setRevealFold(true)
        setReceivedAnswer(answer)
    }

    const Finish = () => {
        setActiveTest(undefined)
        setReceivedAnswer(false)
        setRevealFold(false)
    }

    const Restart = () => {
        setReceivedAnswer(false)
        setRevealFold(false)
    }

    return <>

        < ContentTile >
            <ColumnLayout>
                {activeTest != undefined && <>

                    <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
                        <DisplayItemContentSpace>
                            {/* {props.Data.TestDefinitions[activeTest].AboveFold({ Data: props.Data.Item, OnAnswer: ReceiveAnswer, ShowAnswerInterface: true })} */}
                            {React.createElement(props.Data.TestDefinitions[activeTest].AboveFold, { Data: props.Data.Item, OnAnswer: ReceiveAnswer, ShowAnswerInterface: true })}
                        </DisplayItemContentSpace>
                        <hr />
                        <DisplayItemContentSpace>
                            {revealFold && <>
                                {React.createElement(props.Data.TestDefinitions[activeTest].BelowFold, { Data: props.Data.Item })}
                            </>}
                        </DisplayItemContentSpace>


                        <div style={{ marginBottom: '0', marginTop: 'auto' }}>
                            {revealFold && <div style={{ marginBottom: 0, marginTop: 'auto' }}>
                                {receivedAnswer == true && <TextStyle>✅ Correct ✅</TextStyle>}
                                {receivedAnswer == false && <TextStyle>❌ Incorrect ❌</TextStyle>}
                            </div>}

                            <StyledButton onClick={Restart}><TextStyle case='uppercase' size={1} weight='bold'>Restart</TextStyle></StyledButton >
                            <StyledButton onClick={Finish}><TextStyle case='uppercase' size={1} weight='bold'>Finish</TextStyle></StyledButton >
                        </div>
                    </div>
                </>}

                {activeTest == undefined &&
                    <RowLayout>
                        {
                            Object.entries(props.Data.TestDefinitions).map(([_, td], __) => {

                                return <>
                                    <StyledButton onClick={() => startPracticeTest(td.id)}>{td.Label} </StyledButton>
                                </>
                            })
                        }
                    </RowLayout>
                }

            </ColumnLayout >
        </ContentTile >
    </>
}