import { EmptyBelowFold } from "../../../../Components/TestFolds/Empty"
import { NewZhBaseUnitGlyphToMeaningAboveFold, NewZhBaseUnitGlyphToMeaningBelowFold, NewZhBaseUnitGlyphToSpokenMeaningBelowFold, NewZhBaseUnitGlyphToSpokenReadingAboveFold, NewZhBaseUnitMeaningToWrittenGlyphAboveFold, NewZhBaseUnitMeaningToWrittenGlyphBelowFold } from "../../../../Components/TestFolds/NewZhBaseUnit"
import { NewZhCompositeUnitSentenceBaseAboveFold, NewZhCompositeUnitSentenceBaseBelowFold, ZhCompositeDictationTestAboveFold } from "../../../../Components/TestFolds/NewZhCompositeUnit"
import { ZhBaseUnitGlyphToMeaningAboveFold, ZhBaseUnitGlyphToMeaningBelowFold, ZhBaseUnitMeaningToWrittenGlyphAboveFold, ZhBaseUnitGlyphToSpokenReadingAboveFold } from "../../../../Components/TestFolds/ZhBaseUnit"
import { ZhCompositeUnitSentenceBaseAboveFold, ZhCompositeUnitSentenceBaseBelowFold } from "../../../../Components/TestFolds/ZhCompositeUnit"
import { ZhBaseUnitTestDefinition } from "../../../../Types/Zh/LanguageDefinition"
import { TestDefinition, TestDefinitionMapType } from "../../../UnitDefinitionMap"
import { ZhLanguageTestTypes, ZhLanguageUnitTypes } from "../Types"

export type UnitTestInterfaceConfiguration = Record<number, Record<number, any>>

export const ZhUnitTestInterfaceConfig: UnitTestInterfaceConfiguration = {

    [ZhLanguageUnitTypes.Base]: {

        [ZhLanguageTestTypes.GlyphToMeaning]: {
            id: ZhLanguageTestTypes.GlyphToMeaning,

            Label: 'GlyphToMeaning',
            Reintroduce: false,

            AboveFold: NewZhBaseUnitGlyphToMeaningAboveFold,
            BelowFold: NewZhBaseUnitGlyphToMeaningBelowFold,

            UserIndicatesIfCorrect: false

        },
        [ZhLanguageTestTypes.MeaningReadingToWrittenGlyph]: {
            id: ZhLanguageTestTypes.MeaningReadingToWrittenGlyph,

            Label: 'MeaningToWrittenGlyph',
            Reintroduce: false,

            AboveFold: NewZhBaseUnitMeaningToWrittenGlyphAboveFold,
            BelowFold: NewZhBaseUnitMeaningToWrittenGlyphBelowFold,

            UserIndicatesIfCorrect: false

        },
        [ZhLanguageTestTypes.GlyphToSpokenReading]: {
            id: ZhLanguageTestTypes.GlyphToSpokenReading,

            Label: 'GlyphToSpokenReading',
            Reintroduce: false,

            AboveFold: NewZhBaseUnitGlyphToSpokenReadingAboveFold,
            BelowFold: NewZhBaseUnitGlyphToSpokenMeaningBelowFold,

            UserIndicatesIfCorrect: false

        }
    },
    [ZhLanguageUnitTypes.Composite]: {
        [ZhLanguageTestTypes.CompositeRead]: {
            id: ZhLanguageTestTypes.CompositeRead,

            Label: "SentenceBase",

            AboveFold: NewZhCompositeUnitSentenceBaseAboveFold,
            BelowFold: NewZhCompositeUnitSentenceBaseBelowFold,

            Reintroduce: false,
            UserIndicatesIfCorrect: true,
        },
        [ZhLanguageTestTypes.CompositeDictation]: {
            id: ZhLanguageTestTypes.CompositeDictation,

            Label: "CompositeDictation",

            AboveFold: ZhCompositeDictationTestAboveFold,
            BelowFold: EmptyBelowFold,

            Reintroduce: false,
            UserIndicatesIfCorrect: false,
        }

    }

}