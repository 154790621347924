import { TextStyle } from "../App";
import { ContentTile, StyledButton } from "../Components/ModeTile";
import { UnitLoaderCallbacks, State, UnitControllerState, Reload, SetMenu, SetPreamble, UnitControllerMode } from "../Loaders/LanguageDefinitionLoader";
import { SubUnitDefinition } from "../Config/UnitDefinitionMap";
import { ButtonDisplay, ButtonSubDisplay } from "../Styles/Styles";
import { Data, OnEnter, OnExit } from "../Types/Interfaces";
import { DevDebugView } from "./DevDebugView";
import { LearnView } from "./LearnView";
import { ProcessTextView } from "./ProcessTextView";
import { RoundRecordView } from "./RoundRecordView";
import { SubUnitView } from "./SubUnitView";
import { UnitItemView } from "./UnitItemView";
import { UnitOptionsView } from "./UnitOptionsView";
import { StatsView } from "./StatsView";
import { LanguageDefinitionControllerProps } from "../Loaders/LanguageDefinitionLoader";
import { LanguageConfiguration, NextItemContainer, OrderSelectedUnits, ZhLanguageConfiguration, ZhSelectNextItems } from "../Config/LanguageDefinitionConfiguration";
import { ItemHistoryElement } from "../Database/HistoryState";
import { useState } from "react";
import { RoundRecord } from "./RoundView";
import { LearnViewLoader } from "../Loaders/LearnViewLoader";
import { RoundControllerParams } from "./NewRoundView";
import { SelectItemParametersView } from "./SelectItemParametersView";
import { ZhLanguageDefinition } from "../Config/Language/Zh/Definition";
import { InfiniteReadView } from "./InfiniteReadView";
import { StudyDashboardTile } from "./StudyDashboardTile";
import { ZhLanguageUnitTypes } from "../Config/Language/Zh/Types";
import { SelectUnitParameters } from "../api";

export const LanguageInstanceView: React.FC<
    UnitLoaderCallbacks &
    State<UnitControllerState> &
    // Data<LanguageDefinitionControllerProps> &
    Data<{ Params: RoundControllerParams, LanguageDefinitionConfiguration: LanguageConfiguration<any> }> &
    Reload &
    OnEnter &
    SetMenu &
    SetPreamble &
    OnExit> = props => {

        // const Unit = props.Data.UnitDefinition;

        // const roundHistory = props.Data.RoundRecords

        // const totalCorrect = history.filter(h => h.Score > 0).length

        // const unseen = history.filter(h => !h.Introduced).length
        // const seen = history.length - unseen

        const UnitControllerMenuConfig = [{
            Label: "Back",
            OnClick: () => { ChangeMode(UnitControllerMode.Home); }
        },
        {
            Label: "Exit",
            OnClick: () => { props.SetMode(UnitControllerMode.Home); props.OnExit() }
        }]

        const UnitControllerHomeMenuConfig = [{
            Label: "Exit",
            OnClick: () => { props.SetMode(UnitControllerMode.Home); props.OnExit() }
        }]

        const TakeFocus = (mode: UnitControllerMode) => {
            props.OnEnter()

            ChangeMode(mode)
        }

        const ChangeMode = (mode: UnitControllerMode) => {
            if (mode == UnitControllerMode.Home) {
                props.Reload(UnitControllerMode.Home)
                props.SetMenu(UnitControllerHomeMenuConfig)
            } else {
                props.SetMenu(UnitControllerMenuConfig)
            }

            props.SetPreamble("ZHongwen")
            props.SetMode(mode)

        }

        const SetBySubUnit = (Defn: SubUnitDefinition<any>) => {
            props.SetBySubUnit(Defn)
        }

        const SetByRoundRecord = (record: RoundRecord) => {
            props.SetByRoundRecord(record)
        }

        const AddToMenu = (Menu: any[]) => {

            var baseMenu = UnitControllerMenuConfig
            var menu = [
                ...Menu,
                ...baseMenu
            ]

            props.SetMenu(menu)
        }

        const [selectUnitParameters, setSelectUnitParameters] = useState<SelectUnitParameters>(new SelectUnitParameters())

        const Learn = async () => {

            // const params: SelectUnitParameters = {
            //     Counts: {
            //         New: {
            //             [ZhLanguageUnitTypes.Radical]: props.Data.Params.IntroduceCount,
            //             [ZhLanguageUnitTypes.Hanzi]: props.Data.Params.IntroduceCount / 2,
            //             [ZhLanguageUnitTypes.Word]: props.Data.Params.IntroduceCount / 2,
            //         },
            //         Review: props.Data.Params.RoundSize
            //     },
            //     OrderBy: OrderSelectedUnits.Default,
            //     TestDefinitions: props.Data.Params.TestDefinitions
            // }

            // ZhSelectNextItems(props.Data.LanguageDefinitionConfiguration.Units, params)
            //     .then((units) => { setNextUnits(units); TakeFocus(UnitControllerMode.Learn); })
            var sup = new SelectUnitParameters()
            var x = JSON.parse(localStorage.getItem("selectItemParameters_default")!!)
            sup.parameters = x;
            setSelectUnitParameters(sup)

            TakeFocus(UnitControllerMode.Learn)

        }

        const LanguageDefinition = ZhLanguageDefinition

        return (<>
            <ContentTile style={{ justifyContent: 'center' }}>
                {props.State.ControllerMode == UnitControllerMode.Home && <>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <TextStyle size={8} weight='bold'>{props.Data.LanguageDefinitionConfiguration.Label}</TextStyle>
                        {/* {!props.State.UseSubUnit && <TextStyle size={3} weight='bold' case='uppercase'>{Unit.NameLabel}</TextStyle>} */}
                        {!props.State.Locked && props.State.Loaded && <>

                            <StudyDashboardTile />
                            {/* {props.State.UseSubUnit && <TextStyle size={3} weight='bold' case='uppercase'>{Unit.SubUnits.find(s => s.Id == props.State.SubUnitId)?.Label}</TextStyle>} */}
                            {/* <TextStyle>Learned: {totalCorrect}</TextStyle>
                            {!props.State.UseSubUnit && <TextStyle>Remaining: {unseen}</TextStyle>}
                            {/* {props.State.UseSubUnit && <TextStyle>Remaining: {subUnitHistory.length}</TextStyle>} */}
                            {/* {props.State.Loaded && <TextStyle>Progress: {((seen / history.length) * 100).toFixed(0)}%</TextStyle>} */}

                            <hr />
                            {/* {props.State.UseSubUnit && <TextStyle>SubUnit {props.State.SubUnitId}</TextStyle>} */}
                            {/* {props.State.RetryRound && <TextStyle>RetryRound {props.State.RoundRecordId}</TextStyle>} */}
                            <ButtonDisplay>
                                <StyledButton onClick={() => { Learn(); }}><TextStyle case='uppercase' size={2} weight='bold'>{/*totalCorrect == 0*/ true ? "Begin" : "Continue"}</TextStyle></StyledButton>
                                <StyledButton onClick={() => TakeFocus(UnitControllerMode.InfiniteRead)}><TextStyle case='uppercase' size={2} weight='bold'>Infinite Read</TextStyle></StyledButton>
                                <StyledButton onClick={() => TakeFocus(UnitControllerMode.Stats)}><TextStyle case='uppercase' size={2} weight='bold'>Stats</TextStyle></StyledButton>
                                <StyledButton onClick={() => TakeFocus(UnitControllerMode.Options)}><TextStyle case='uppercase' size={2} weight='bold'>Options</TextStyle></StyledButton>

                                <ButtonSubDisplay>
                                    {/* <StyledButton onClick={() => TakeFocus(UnitControllerMode.Display)}><TextStyle case='uppercase' size={2} weight='bold'>View</TextStyle></StyledButton> */}
                                    {/* <StyledButton onClick={() => TakeFocus(UnitControllerMode.ViewRoundRecords)}><TextStyle case='uppercase' size={2} weight='bold'>History</TextStyle></StyledButton> */}
                                    {/* {Unit.SubUnits.length > 0 && <StyledButton onClick={() => TakeFocus(UnitControllerMode.SubUnits)}><TextStyle case='uppercase' size={2} weight='bold'>Sub-Units</TextStyle></StyledButton>} */}
                                </ButtonSubDisplay>
                                <ButtonSubDisplay>
                                    {/* <StyledButton onClick={() => { }}><TextStyle case='uppercase' size={2} weight='bold'>Texts</TextStyle></StyledButton> */}
                                    {/* <StyledButton onClick={() => { }}><TextStyle case='uppercase' size={2} weight='bold'>Chat</TextStyle></StyledButton> */}
                                </ButtonSubDisplay>
                                <ButtonSubDisplay>

                                </ButtonSubDisplay>
                                <ButtonSubDisplay>
                                    {/* <StyledButton onClick={() => TakeFocus(UnitControllerMode.DebugDev)}><TextStyle case='uppercase' size={2} weight='bold'>Debug/Dev</TextStyle></StyledButton> */}
                                </ButtonSubDisplay>
                            </ButtonDisplay>



                        </>}
                        {props.State.Locked && <>
                            <TextStyle>This unit is locked.</TextStyle>
                        </>}
                        {!props.State.Loaded && <>
                            <TextStyle>Loading...</TextStyle>
                        </>}
                    </div>
                </>}

                {props.State.ControllerMode == UnitControllerMode.Display && <>
                    {/* <UnitItemView Data={{ Unit: props.Data.UnitDefinition, History: history }} /> */}
                </>}

                {props.State.ControllerMode == UnitControllerMode.Learn && <>
                    <LearnViewLoader
                        Data={{...props.Data.Params, parameters: selectUnitParameters}}
                        SetMenu={props.SetMenu}
                        OnExit={() => { ChangeMode(UnitControllerMode.Home) }}
                    />
                </>}
                {props.State.ControllerMode == UnitControllerMode.InfiniteRead && <>
                    <InfiniteReadView />
                </>}
                {props.State.ControllerMode == UnitControllerMode.SubUnits && <>
                    {/* <SubUnitView SetData={SetBySubUnit}
                        Data={{
                            Unit: Unit,
                            History: history
                        }}
                    /> */}
                </>}
                {props.State.ControllerMode == UnitControllerMode.Options && <>
                    <SelectItemParametersView
                        SetData={(parameters) => { localStorage.setItem("selectItemParameters_default", JSON.stringify(parameters)) }}
                        Data={{ UnitTypes: [ZhLanguageUnitTypes.Base, ZhLanguageUnitTypes.Extra, ZhLanguageUnitTypes.BaseFromTexts, ZhLanguageUnitTypes.Composite], LanguageDefinition: LanguageDefinition }}
                    />
                    {/* <UnitOptionsView
                        Data={{ LanguageId: props.Data.LanguageDefinitionConfiguration.Id, Params: props.Data.Params }}
                        SetMenu={props.SetMenu}
                        ParentMenuConfig={UnitControllerMenuConfig}
                        SetData={() => {
                            ChangeMode(UnitControllerMode.Home);
                        }} /> */}
                </>}
                {props.State.ControllerMode == UnitControllerMode.ViewRoundRecords && <>
                    {/* <RoundRecordView SetData={SetByRoundRecord} Data={{ Unit: Unit, RoundRecords: roundHistory }} /> */}
                </>}
                {props.State.ControllerMode == UnitControllerMode.DebugDev && <>
                    {/* <DevDebugView SetData={() => { ChangeMode(UnitControllerMode.Home) }} Data={{ Unit: Unit }} /> */}
                </>}
                {props.State.ControllerMode == UnitControllerMode.Stats && <>
                    <StatsView />
                </>}
                {props.State.ControllerMode == UnitControllerMode.Texts && <>
                </>}
                {props.State.ControllerMode == UnitControllerMode.Chat && <>

                </>}
            </ContentTile>
        </>)
    }



