import { TextStyle } from "../../App"
import { ColumnLayout, RowLayout } from "../../Styles/Styles"
import { DisplayItemAboveFoldProps, DisplayItemBelowFoldProps, NewDisplayItemAboveFoldProps, NewDisplayItemBelowFoldProps } from "../../Types/DisplayItem"
import { PlayTextAudio } from "../PlayTextAudio"
import { AudioRecorderInterface } from "./NewZhBaseUnit"

export type ZhCompositeUnitType = { Id: string, Target: string, Primary: string }

export const NewZhCompositeUnitSentenceBaseAboveFold: React.FC<NewDisplayItemAboveFoldProps> = props => {

    return <>
        <TextStyle size={2} weight='bold'>{props.Data.properties['text']}</TextStyle>
    </>
}

export const NewZhCompositeUnitSentenceBaseBelowFold: React.FC<NewDisplayItemBelowFoldProps> = props => {

    return <>
        <TextStyle size={1} weight='bold'>{props.Data.properties['primary']}</TextStyle>
    </>
}

export const ZhCompositeDictationTestAboveFold: React.FC<NewDisplayItemAboveFoldProps> = props => {

    return <>
        <TextStyle size={10} weight='bold'>{props.Data.properties['text']}</TextStyle>

        {props.ShowAnswerInterface &&
            <>
                <ColumnLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <PlayTextAudio Text={props.Data.properties['text']} />
                        <AudioRecorderInterface Unit={props.Data.properties['text']} OnAnswer={props.OnAnswer!!} />
                    </RowLayout>
                </ColumnLayout>
            </>
        }


        {/* <RowLayout>
            <StyledButton onClick={() => props.OnAnswer!!(true)}>Correct</StyledButton>
            <StyledButton onClick={() => props.OnAnswer!!(false)}>Incorrect</StyledButton>
        </RowLayout> */}
    </>
}