import { ResponseContext, RequestContext, HttpFile, HttpInfo } from '../http/http';
import { Configuration} from '../configuration'

import { AnswerRecord } from '../models/AnswerRecord';
import { ApiRoundItemData } from '../models/ApiRoundItemData';
import { ApiRoundRecord } from '../models/ApiRoundRecord';
import { AssistValidateRequest } from '../models/AssistValidateRequest';
import { BasicStatsQuery } from '../models/BasicStatsQuery';
import { BasicStatsResponse } from '../models/BasicStatsResponse';
import { DashboardResponse } from '../models/DashboardResponse';
import { DictionaryEntry } from '../models/DictionaryEntry';
import { DictionaryEntryDefinition } from '../models/DictionaryEntryDefinition';
import { DictionaryEntryDefinitionExamples } from '../models/DictionaryEntryDefinitionExamples';
import { DictionaryEntryType } from '../models/DictionaryEntryType';
import { DictionaryQueryResult } from '../models/DictionaryQueryResult';
import { EntryExtraData } from '../models/EntryExtraData';
import { GenerateAudioRequest } from '../models/GenerateAudioRequest';
import { GenerateAudioResponse } from '../models/GenerateAudioResponse';
import { GenerateTextRequest } from '../models/GenerateTextRequest';
import { GeneratedTextStyle } from '../models/GeneratedTextStyle';
import { ItemData } from '../models/ItemData';
import { ItemHistory } from '../models/ItemHistory';
import { KeyContainer } from '../models/KeyContainer';
import { LanguageTextPair } from '../models/LanguageTextPair';
import { LookupQuery } from '../models/LookupQuery';
import { Message } from '../models/Message';
import { Messages } from '../models/Messages';
import { PartialChapter } from '../models/PartialChapter';
import { PartialParagraph } from '../models/PartialParagraph';
import { PartialSentence } from '../models/PartialSentence';
import { PartialTextContainer } from '../models/PartialTextContainer';
import { PartialTextContainerStructure } from '../models/PartialTextContainerStructure';
import { PartialTextNode } from '../models/PartialTextNode';
import { PartialTextStructure } from '../models/PartialTextStructure';
import { PartialTextStructureType } from '../models/PartialTextStructureType';
import { PickRoundRecordExcludeKeyofRoundRecordItems } from '../models/PickRoundRecordExcludeKeyofRoundRecordItems';
import { ProcessTextRequest } from '../models/ProcessTextRequest';
import { ProcessTextRequest2 } from '../models/ProcessTextRequest2';
import { ProcessedChapter } from '../models/ProcessedChapter';
import { ProcessedParagraph } from '../models/ProcessedParagraph';
import { ProcessedSentence } from '../models/ProcessedSentence';
import { ProcessedTextStructure } from '../models/ProcessedTextStructure';
import { ProcessedTextStructure2 } from '../models/ProcessedTextStructure2';
import { ProcessedTextStructure2Structure } from '../models/ProcessedTextStructure2Structure';
import { Query } from '../models/Query';
import { RegisterAccount } from '../models/RegisterAccount';
import { RoundItemData } from '../models/RoundItemData';
import { Sample } from '../models/Sample';
import { SegmentSentenceRequest } from '../models/SegmentSentenceRequest';
import { SegmentationOutput } from '../models/SegmentationOutput';
import { SegmentedText } from '../models/SegmentedText';
import { SelectItemConfiguration } from '../models/SelectItemConfiguration';
import { SelectReviewByOption } from '../models/SelectReviewByOption';
import { SelectUnitParameters } from '../models/SelectUnitParameters';
import { SelectedItems } from '../models/SelectedItems';
import { SentenceSegmentationResult } from '../models/SentenceSegmentationResult';
import { StatisticsQueryNewItemSummaryType } from '../models/StatisticsQueryNewItemSummaryType';
import { StatisticsQueryRoundSummaryType } from '../models/StatisticsQueryRoundSummaryType';
import { TextDifficulty } from '../models/TextDifficulty';
import { TextEntry } from '../models/TextEntry';
import { TextLength } from '../models/TextLength';
import { TextPair } from '../models/TextPair';
import { TextPromptType } from '../models/TextPromptType';
import { TextType } from '../models/TextType';
import { TranslateResponse } from '../models/TranslateResponse';
import { TranslateResponseCost } from '../models/TranslateResponseCost';
import { TranslateResponseOutput } from '../models/TranslateResponseOutput';
import { UserGradeState } from '../models/UserGradeState';
import { ObservableDefaultApi } from './ObservableAPI';

import { DefaultApiRequestFactory, DefaultApiResponseProcessor} from "../apis/DefaultApi";
export class PromiseDefaultApi {
    private api: ObservableDefaultApi

    public constructor(
        configuration: Configuration,
        requestFactory?: DefaultApiRequestFactory,
        responseProcessor?: DefaultApiResponseProcessor
    ) {
        this.api = new ObservableDefaultApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param query 
     */
    public assistExamplesWithHttpInfo(query: Query, _options?: Configuration): Promise<HttpInfo<TranslateResponse>> {
        const result = this.api.assistExamplesWithHttpInfo(query, _options);
        return result.toPromise();
    }

    /**
     * @param query 
     */
    public assistExamples(query: Query, _options?: Configuration): Promise<TranslateResponse> {
        const result = this.api.assistExamples(query, _options);
        return result.toPromise();
    }

    /**
     * @param query 
     */
    public assistExplainWithHttpInfo(query: Query, _options?: Configuration): Promise<HttpInfo<string>> {
        const result = this.api.assistExplainWithHttpInfo(query, _options);
        return result.toPromise();
    }

    /**
     * @param query 
     */
    public assistExplain(query: Query, _options?: Configuration): Promise<string> {
        const result = this.api.assistExplain(query, _options);
        return result.toPromise();
    }

    /**
     * @param query 
     */
    public assistTranslateWithHttpInfo(query: Query, _options?: Configuration): Promise<HttpInfo<string>> {
        const result = this.api.assistTranslateWithHttpInfo(query, _options);
        return result.toPromise();
    }

    /**
     * @param query 
     */
    public assistTranslate(query: Query, _options?: Configuration): Promise<string> {
        const result = this.api.assistTranslate(query, _options);
        return result.toPromise();
    }

    /**
     * @param assistValidateRequest 
     */
    public assistValidateWithHttpInfo(assistValidateRequest: AssistValidateRequest, _options?: Configuration): Promise<HttpInfo<TranslateResponse>> {
        const result = this.api.assistValidateWithHttpInfo(assistValidateRequest, _options);
        return result.toPromise();
    }

    /**
     * @param assistValidateRequest 
     */
    public assistValidate(assistValidateRequest: AssistValidateRequest, _options?: Configuration): Promise<TranslateResponse> {
        const result = this.api.assistValidate(assistValidateRequest, _options);
        return result.toPromise();
    }

    /**
     * @param messages 
     */
    public assistantChatWithHttpInfo(messages: Messages, _options?: Configuration): Promise<HttpInfo<string>> {
        const result = this.api.assistantChatWithHttpInfo(messages, _options);
        return result.toPromise();
    }

    /**
     * @param messages 
     */
    public assistantChat(messages: Messages, _options?: Configuration): Promise<string> {
        const result = this.api.assistantChat(messages, _options);
        return result.toPromise();
    }

    /**
     * @param basicStatsQuery 
     */
    public basicStatsWithHttpInfo(basicStatsQuery: BasicStatsQuery, _options?: Configuration): Promise<HttpInfo<BasicStatsResponse>> {
        const result = this.api.basicStatsWithHttpInfo(basicStatsQuery, _options);
        return result.toPromise();
    }

    /**
     * @param basicStatsQuery 
     */
    public basicStats(basicStatsQuery: BasicStatsQuery, _options?: Configuration): Promise<BasicStatsResponse> {
        const result = this.api.basicStats(basicStatsQuery, _options);
        return result.toPromise();
    }

    /**
     * @param keyContainer 
     */
    public checkKeyWithHttpInfo(keyContainer: KeyContainer, _options?: Configuration): Promise<HttpInfo<boolean>> {
        const result = this.api.checkKeyWithHttpInfo(keyContainer, _options);
        return result.toPromise();
    }

    /**
     * @param keyContainer 
     */
    public checkKey(keyContainer: KeyContainer, _options?: Configuration): Promise<boolean> {
        const result = this.api.checkKey(keyContainer, _options);
        return result.toPromise();
    }

    /**
     */
    public dashboardWithHttpInfo(_options?: Configuration): Promise<HttpInfo<DashboardResponse>> {
        const result = this.api.dashboardWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public dashboard(_options?: Configuration): Promise<DashboardResponse> {
        const result = this.api.dashboard(_options);
        return result.toPromise();
    }

    /**
     * @param ref 
     */
    public deleteTextWithHttpInfo(ref: string, _options?: Configuration): Promise<HttpInfo<void>> {
        const result = this.api.deleteTextWithHttpInfo(ref, _options);
        return result.toPromise();
    }

    /**
     * @param ref 
     */
    public deleteText(ref: string, _options?: Configuration): Promise<void> {
        const result = this.api.deleteText(ref, _options);
        return result.toPromise();
    }

    /**
     * @param image 
     */
    public evaluateImageWithHttpInfo(image: HttpFile, _options?: Configuration): Promise<HttpInfo<SentenceSegmentationResult>> {
        const result = this.api.evaluateImageWithHttpInfo(image, _options);
        return result.toPromise();
    }

    /**
     * @param image 
     */
    public evaluateImage(image: HttpFile, _options?: Configuration): Promise<SentenceSegmentationResult> {
        const result = this.api.evaluateImage(image, _options);
        return result.toPromise();
    }

    /**
     * @param reference 
     * @param audio 
     */
    public evaluatePronunciationWithHttpInfo(reference: string, audio: HttpFile, _options?: Configuration): Promise<HttpInfo<any>> {
        const result = this.api.evaluatePronunciationWithHttpInfo(reference, audio, _options);
        return result.toPromise();
    }

    /**
     * @param reference 
     * @param audio 
     */
    public evaluatePronunciation(reference: string, audio: HttpFile, _options?: Configuration): Promise<any> {
        const result = this.api.evaluatePronunciation(reference, audio, _options);
        return result.toPromise();
    }

    /**
     * @param apiRoundRecord 
     */
    public finishRoundWithHttpInfo(apiRoundRecord: ApiRoundRecord, _options?: Configuration): Promise<HttpInfo<any>> {
        const result = this.api.finishRoundWithHttpInfo(apiRoundRecord, _options);
        return result.toPromise();
    }

    /**
     * @param apiRoundRecord 
     */
    public finishRound(apiRoundRecord: ApiRoundRecord, _options?: Configuration): Promise<any> {
        const result = this.api.finishRound(apiRoundRecord, _options);
        return result.toPromise();
    }

    /**
     * @param generateAudioRequest 
     */
    public generateAudioWithHttpInfo(generateAudioRequest: GenerateAudioRequest, _options?: Configuration): Promise<HttpInfo<GenerateAudioResponse>> {
        const result = this.api.generateAudioWithHttpInfo(generateAudioRequest, _options);
        return result.toPromise();
    }

    /**
     * @param generateAudioRequest 
     */
    public generateAudio(generateAudioRequest: GenerateAudioRequest, _options?: Configuration): Promise<GenerateAudioResponse> {
        const result = this.api.generateAudio(generateAudioRequest, _options);
        return result.toPromise();
    }

    /**
     * @param generateTextRequest 
     */
    public generateTextWithHttpInfo(generateTextRequest: GenerateTextRequest, _options?: Configuration): Promise<HttpInfo<ProcessedTextStructure>> {
        const result = this.api.generateTextWithHttpInfo(generateTextRequest, _options);
        return result.toPromise();
    }

    /**
     * @param generateTextRequest 
     */
    public generateText(generateTextRequest: GenerateTextRequest, _options?: Configuration): Promise<ProcessedTextStructure> {
        const result = this.api.generateText(generateTextRequest, _options);
        return result.toPromise();
    }

    /**
     * @param ref 
     */
    public getTextWithHttpInfo(ref: string, _options?: Configuration): Promise<HttpInfo<ProcessedTextStructure>> {
        const result = this.api.getTextWithHttpInfo(ref, _options);
        return result.toPromise();
    }

    /**
     * @param ref 
     */
    public getText(ref: string, _options?: Configuration): Promise<ProcessedTextStructure> {
        const result = this.api.getText(ref, _options);
        return result.toPromise();
    }

    /**
     */
    public getTextsWithHttpInfo(_options?: Configuration): Promise<HttpInfo<Array<TextEntry>>> {
        const result = this.api.getTextsWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public getTexts(_options?: Configuration): Promise<Array<TextEntry>> {
        const result = this.api.getTexts(_options);
        return result.toPromise();
    }

    /**
     * @param lookupQuery 
     */
    public lookupQueryWithHttpInfo(lookupQuery: LookupQuery, _options?: Configuration): Promise<HttpInfo<DictionaryQueryResult>> {
        const result = this.api.lookupQueryWithHttpInfo(lookupQuery, _options);
        return result.toPromise();
    }

    /**
     * @param lookupQuery 
     */
    public lookupQuery(lookupQuery: LookupQuery, _options?: Configuration): Promise<DictionaryQueryResult> {
        const result = this.api.lookupQuery(lookupQuery, _options);
        return result.toPromise();
    }

    /**
     * @param processTextRequest 
     */
    public processTextWithHttpInfo(processTextRequest: ProcessTextRequest, _options?: Configuration): Promise<HttpInfo<ProcessedTextStructure>> {
        const result = this.api.processTextWithHttpInfo(processTextRequest, _options);
        return result.toPromise();
    }

    /**
     * @param processTextRequest 
     */
    public processText(processTextRequest: ProcessTextRequest, _options?: Configuration): Promise<ProcessedTextStructure> {
        const result = this.api.processText(processTextRequest, _options);
        return result.toPromise();
    }

    /**
     * @param processTextRequest2 
     */
    public processText2WithHttpInfo(processTextRequest2: ProcessTextRequest2, _options?: Configuration): Promise<HttpInfo<ProcessedTextStructure2>> {
        const result = this.api.processText2WithHttpInfo(processTextRequest2, _options);
        return result.toPromise();
    }

    /**
     * @param processTextRequest2 
     */
    public processText2(processTextRequest2: ProcessTextRequest2, _options?: Configuration): Promise<ProcessedTextStructure2> {
        const result = this.api.processText2(processTextRequest2, _options);
        return result.toPromise();
    }

    /**
     * @param registerAccount 
     */
    public registerWithHttpInfo(registerAccount: RegisterAccount, _options?: Configuration): Promise<HttpInfo<boolean>> {
        const result = this.api.registerWithHttpInfo(registerAccount, _options);
        return result.toPromise();
    }

    /**
     * @param registerAccount 
     */
    public register(registerAccount: RegisterAccount, _options?: Configuration): Promise<boolean> {
        const result = this.api.register(registerAccount, _options);
        return result.toPromise();
    }

    /**
     * @param segmentSentenceRequest 
     */
    public segmentSentencesWithHttpInfo(segmentSentenceRequest: SegmentSentenceRequest, _options?: Configuration): Promise<HttpInfo<SentenceSegmentationResult>> {
        const result = this.api.segmentSentencesWithHttpInfo(segmentSentenceRequest, _options);
        return result.toPromise();
    }

    /**
     * @param segmentSentenceRequest 
     */
    public segmentSentences(segmentSentenceRequest: SegmentSentenceRequest, _options?: Configuration): Promise<SentenceSegmentationResult> {
        const result = this.api.segmentSentences(segmentSentenceRequest, _options);
        return result.toPromise();
    }

    /**
     * @param selectUnitParameters 
     */
    public selectUnitsWithHttpInfo(selectUnitParameters: SelectUnitParameters, _options?: Configuration): Promise<HttpInfo<SelectedItems>> {
        const result = this.api.selectUnitsWithHttpInfo(selectUnitParameters, _options);
        return result.toPromise();
    }

    /**
     * @param selectUnitParameters 
     */
    public selectUnits(selectUnitParameters: SelectUnitParameters, _options?: Configuration): Promise<SelectedItems> {
        const result = this.api.selectUnits(selectUnitParameters, _options);
        return result.toPromise();
    }

    /**
     */
    public viewGradesWithHttpInfo(_options?: Configuration): Promise<HttpInfo<Array<UserGradeState>>> {
        const result = this.api.viewGradesWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public viewGrades(_options?: Configuration): Promise<Array<UserGradeState>> {
        const result = this.api.viewGrades(_options);
        return result.toPromise();
    }


}



